// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 10,
		slug: 'border-collie',
		title: 'Border Collie: Sheep Dog',
		category: 'Web Games',
		img: require('@/assets/images/border_collie/border-collie.png'),
		date: 'Oct, 2023',
		details: 'An arcade game where you control a border collie',
		description: '“Border Collie: Sheep Dog” is a game where you control a border collie ' +
						'and your goal is to herd all the sheep into the pen. ' +
						'Get ready for exciting adventures on the farm and test your dog-handling skills ' +
						'in this captivating game. Enjoy! 🐶🐑',
		platform_name: 'Yandex Games',
		website: 'https://yandex.com/games/app/259104',
		techs: [
			'Unity'
		],
		imgs: [
			require('@/assets/images/border_collie/gluck1.png'),
			require('@/assets/images/border_collie/gluck2.png'),
			require('@/assets/images/border_collie/gluck3.png'),
		]
	},
	{
		id: 11,
		slug: 'pushifka',
		title: 'Puffskein Adventure',
		category: 'Web Games',
		img: require('@/assets/images/puffskein/puffskein.png'),
		date: 'Jul, 2023',
		details: 'An platform game where you control a fluffy puffskein',
		description: 'In this game, you control a cute fluffy animal with ears, paws and eyes, ' +
						'going on an exciting journey through various levels ' +
						'and overcoming various obstacles.',
		platform_name: 'Yandex Games',
		website: 'https://yandex.com/games/app/232766',
		techs: [
			'Unity'
		],
		imgs: [
			require('@/assets/images/puffskein/puff1.png'),
			require('@/assets/images/puffskein/puff2.png'),
			require('@/assets/images/puffskein/puff3.png'),
		]
	},
	{
		id: 1,
		slug: 'slica',
		title: 'Slica. Neuro puzzles',
		category: 'Web Games',
		img: require('@/assets/images/slica.jpg'),
		date: 'Dec, 2022',
		details: 'A puzzle game with daily generated pictures from AI.',
		description: 'Neural network generates unique pictures every day, player needs to complete the mosaic in fewer steps. That\'s very simple - two random cells must be selected and they will change their positions. As soon as player completes the puzzle, he is able to replay on a harder level or to choose another unique picture.',
		platform_name: 'Yandex Games',
		service: 'UI Design & Frontend Development',
		website: 'https://yandex.com/games/app/201142',
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'Figma',
		],
		imgs: [
			require('@/assets/images/image_slica_1.png'),
			require('@/assets/images/image_slica_2.png'),
			require('@/assets/images/image_slica_3.png'),
		],
	},
	{
		id: 2,
		slug: 'girls-puzzles-and-anime',
		title: 'Girls, puzzles and anime',
		category: 'Web Games',
		img: require('@/assets/images/anime-slica.jpg'),
		date: 'Jan, 2023',
		details: 'A puzzle game with daily generated pictures of anime girls from AI.',
		description: 'Neural network generates unique pictures of anime-girls every day, player needs to complete the mosaic in fewer steps. That\'s very simple - two random cells must be selected and they will change their positions. As soon as player completes the puzzle, he is able to replay on a harder level or to choose another unique picture!',
		platform_name: 'Yandex Games',
		service: 'UI Design & Frontend Development',
		website: 'https://yandex.com/games/app/210816',
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'Figma',
		],
		imgs: [
			require('@/assets/images/image_anime_slica_1.png'),
			require('@/assets/images/image_anime_slica_2.png'),
			require('@/assets/images/image_anime_slica_3.png'),
		],
	},
	{
		id: 3,
		slug: 'matrix-word',
		title: 'Matrix Word',
		category: 'Web Games [archived]',
		img: require('@/assets/images/matrix-word.jpg'),
		date: 'Feb, 2023',
		details: 'A puzzle game with fill-words mechanics.',
		description: 'Matrix Word is a puzzle in the style of "collect the word" with unique mechanics! The game resembles "words from words", fill words - everything that we know and love! User should collect as many words as possible and compete with other users!',
		platform_name: 'Yandex Games',
		service: 'UI Design & Frontend Development',
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'Figma',
		],
		imgs: [
			require('@/assets/images/image_matrix_1.png'),
			require('@/assets/images/image_matrix_2.png'),
			require('@/assets/images/image_matrix_3.png'),
		],
	},
	{
		id: 4,
		slug: 'spin-planer-round',
		title: 'Spin Planet Round',
		category: 'Web Games [archived]',
		img: require('@/assets/images/spin-planet.jpg'),
		date: 'Nov, 2022',
		details: '"Destroy the enemy" game developed using Unity.',
		description: 'User should destroy as many enemy planets as possible! The mechanics is simply easy - spinning around and throwing asteroids at enemies.',
		platform_name: 'Yandex Games',
		service: 'UI Design & Game Development',
		techs: [
			'HTML',
			'CSS',
			'Unity',
		],
		imgs: [
			require('@/assets/images/planet-pic-1.png'),
			require('@/assets/images/planet-pic-2.png'),
			require('@/assets/images/planet-pic-3.png'),
		],
	},
	{
		id: 5,
		slug: 'the-world-after-the-disaster',
		title: 'The world after the disaster',
		category: 'Web Games [archived]',
		img: require('@/assets/images/novelx.jpg'),
		date: 'Jan, 2023',
		details: 'Novel-like game.',
		description: 'The novel reveals the story of the future in a parallel universe. The main characters learn a terrible secret that people have kept for 200 years! Player has to help the main character get to the bottom of the matter and prevent the impending disaster.',
		service: 'UI Design & Frontend Development',
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Figma',
		],
		imgs: [
			require('@/assets/images/novel-pic-1.png'),
			require('@/assets/images/novel-pic-2.png'),
			require('@/assets/images/novel-pic-3.png'),
		],
	},
	{
		id: 6,
		slug: 'neuro-crocodile',
		title: 'Neuro Crocodile',
		category: 'Web Games',
		img: require('@/assets/images/croco.png'),
		date: 'Apr, 2023',
		description: 'User has 42 seconds to draw a word while AI tries to guess it.',
		details: 'A crocodile classic game with AI as a teammate.',
		platform_name: 'Yandex Games',
		service: 'UI Design & Frontend Development & Machine Learning',
		website: 'https://yandex.com/games/app/223098',
		techs: [
			'Keras',
			'Tensorflow',
			'Python',
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'Figma',
		],
		imgs: [
			require('@/assets/images/croco-pic-1.png'),
			require('@/assets/images/croco-pic-2.png'),
			require('@/assets/images/croco-pic-3.png'),
		],
	},
	{
		id: 7,
		slug: 'matrix-numbers',
		title: 'Matrix Numbers',
		category: 'Web Games [archived]',
		img: require('@/assets/images/matrix-nums.png'),
		date: 'Apr, 2023',
		details: 'A puzzle, find the amount game.',
		description: '"Matrix Numbers" is a puzzle in which you need to find the sum from the numbers scattered across the field.',
		platform_name: 'Yandex Games',
		service: 'UI Design & Frontend Development',
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'Figma',
		],
		imgs: [
			require('@/assets/images/nums-pic-1.png'),
			require('@/assets/images/nums-pic-2.png'),
			require('@/assets/images/nums-pic-3.png'),
		],
	},
	{
		id: 8,
		slug: 'anrealm-art',
		title: 'Anrealm Art',
		category: 'Channels',
		img: require('@/assets/images/arts-sample.png'),
		description: 'Public telegram channel for sharing AI arts!',
		platform_name: 'Public channel',
		service: 'Sharing AI computed works',
		website: 'https://t.me/anrealm_art',
		techs: [
			'MidJourney',
			'Stable Diffusion',
		],
		imgs: [
			require('@/assets/images/art-pic-1.png'),
			require('@/assets/images/art-pic-2.png'),
			require('@/assets/images/art-pic-3.png'),
		],
	},
];

var map_projects = {};
projects.forEach((x) => {
	map_projects[x.slug] = x;
})

export default projects;
export {map_projects};
